import { nfa } from '@netflix/nfa-logger/lib/helper';
// import { FullStory } from '@fullstory/browser';
export type SerializedAssetId = string;

export const thumbnailUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/thumbnail${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const proxyUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/proxy${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const previewUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/preview${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const formattedAppName = (app: string): string => {
  switch (app) {
    case `VANDAM`:
      return `VanDAM`;
    case `PAL`:
      return `PAL`;
    default:
      return app;
  }
};

export const logEvent = (name: string, category: string, attributes?: unknown): void => {
  nfa.logFeatureEngaged(name, category, attributes);
  // if we want to swap to fullstory
  /* const prop =attributes ? {
    category: category,
    ...attributes,
  } : {
    category: category,
  }
  FullStory('trackEvent', {name: name,
      properties: prop});
   */
};

export const logDuration = (name: string, duration: number) => {
  nfa.reportDuration(name, duration);
};

export const logError = (e: Error): void => {
  nfa.logExceptionOccurred(e);
  // if we want to swap to fullstory
  /* FullStory('trackEvent', {name: e.message ,
      properties: { category: 'error'}});
   */
};
