import { Feedback } from '@hawkins/components';
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@hawkins/styles';

const useStyles = makeStyles(({ Utils }) => ({
  root: {
    width: '85%',
    [Utils.breakpoints.up('BreakpointXsmall')]: {
      width: 450,
    },
    [Utils.breakpoints.up('BreakpointXsmall')]: {
      width: 'initial',
    },
  },
}));

export const WarningBanner = () => {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = useCallback(() => setIsOpen(false), []);
  const classNames = useStyles();
  return (
    <Feedback
      classes={{ root: classNames.root }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={6000}
      message='This site and its contents are intended for authorized users only.'
      variant='warning'
      data-testid={'warning-banner'}
      position='bottom-start'
    />
  );
};
