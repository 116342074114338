import React, { useContext } from 'react';
import { AppLogo, Box, Heading, HorizontalNavigation, Text, HoverCard, ContextualBanner } from '@hawkins/components';
import { Colors, Dimensions } from '@hawkins/variables';
import { DateTime } from 'luxon';

import { useScreenContext } from '../../hooks';
import { BatchContext } from '../../context';
import { LoginButton } from '../LoginButton';
import { DownloadAllAssets } from '../DownloadAll';
import { logError } from '../../util';

export const Header = (): JSX.Element => {
  const { isHeightSmall } = useScreenContext();
  const batch = useContext(BatchContext);
  if (!batch || !batch.share) {
    logError(new Error('cannot be rendered without data'));
    throw new Error('cannot be rendered without data');
  }
  return (
    <>
      <HorizontalNavigation
        transparent
        logo={<AppLogo name='Asset Share' studioLogo envLabel={isTest ? 'test' : undefined} />}
        style={{ backgroundColor: Colors.BlackT40 }}
        userControls={isHeightSmall ? <LoginButton batch={batch} compact /> : undefined}
      >
        {isHeightSmall ? <Heading level={2}>{batch.name}</Heading> : undefined}
      </HorizontalNavigation>
      <Box
        style={{ backgroundColor: Colors.BlackT40 }}
        padding={`0 ${Dimensions.Space24} ${isHeightSmall ? Dimensions.Space12 : Dimensions.Space24} ${
          Dimensions.Space24
        }`}
        gap={Dimensions.Space12}
        display='flex'
        flexDirection='column'
      >
        {isHeightSmall ? (
          <RenderShareInfo />
        ) : (
          <>
            <Heading level={isHeightSmall ? 0 : 5}>{batch.name}</Heading>
            <RenderShareInfo />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <LoginButton batch={batch} />
              <DownloadAllAssets />
            </div>
          </>
        )}
      </Box>
      {batch.assetCount !== batch?.assetIds?.length ? (
        <Box
          padding={`0 ${Dimensions.Space24} ${isHeightSmall ? Dimensions.Space12 : Dimensions.Space24} ${
            Dimensions.Space24
          }`}
        >
          <ContextualBanner
            text={`Please note: We are currently processing ${
              batch?.assetCount - batch?.assetIds?.length
            } assets. We appreciate your patience and invite you to check back in a few minutes. Thank you for your understanding!`}
            variant='warning'
          />
        </Box>
      ) : null}
    </>
  );
};

const RenderShareInfo = () => {
  const batch = useContext(BatchContext);
  if (!batch || !batch.share) {
    throw new Error('cannot be rendered without data');
  }
  return (
    <Box gap={Dimensions.Space4} display='flex' flexDirection='row' alignItems='center'>
      <Text variant='label' level={1} colorVariant='faded'>
        {batch.assetCount} assets &middot;
      </Text>
      <HoverCard>
        <HoverCard.Trigger>
          <Text variant='label' level={1} colorVariant='faded'>
            Created {DateTime.fromMillis(batch.share.createdAt).toRelative()} &middot;
          </Text>
        </HoverCard.Trigger>
        <HoverCard.Content>
          <HoverCard.TooltipContent
            label={DateTime.fromMillis(batch.share.createdAt).toLocaleString(DateTime.DATETIME_FULL)}
          />
        </HoverCard.Content>
      </HoverCard>
      <HoverCard>
        <HoverCard.Trigger>
          <Text variant='label' level={1} colorVariant='faded'>
            Expires {DateTime.fromMillis(batch.share.endDate).toRelative()}
          </Text>
        </HoverCard.Trigger>
        <HoverCard.Content>
          <HoverCard.TooltipContent
            label={DateTime.fromMillis(batch.share.endDate).toLocaleString(DateTime.DATETIME_FULL)}
          />
        </HoverCard.Content>
      </HoverCard>
    </Box>
  );
};

const isTest = window.location.hostname.includes('test') || window.location.hostname.includes('local');
