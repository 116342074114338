import { Tag } from '@hawkins/components';
import React from 'react';
import { Colors } from '@hawkins/variables';

export const MissingThumbnail = () => {
  return (
    <Tag variant={'outlined'} color={Colors.GrayT20}>
      Missing Thumbnail
    </Tag>
  );
};
