import { AppLogo, ErrorState } from '@hawkins/components';
import { Dimensions } from '@hawkins/variables';
import React from 'react';

import Astronaut from '../../astronaut.png';

export const ShowError = (): JSX.Element => {
  return (
    <ErrorState
      layout={{
        padding: Dimensions.Space24,
      }}
      appLogo={<AppLogo name='Asset Share' studioLogo />}
      illustration={<img src={Astronaut} alt='' />}
      subtitle='Verify that your share link is valid. Reach out to your contact at Netflix if you have questions.'
      title={`This page doesn't exist or isn't accessible.`}
    />
  );
};
