import {
  Modal,
  AppLogo,
  Button,
  Heading,
  ModalBody,
  ModalHeader,
  Loader,
  MenuItem,
  MenuGroup,
  Text,
} from '@hawkins/components';
import { IconX, IconDownload } from '@hawkins/assets';
import { Dimensions } from '@hawkins/variables';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import bytes from 'bytes';

import { BatchContext, PasswordContext } from '../../context';
import { PublicAssetFile, useAssetDetail } from '../batch/useDetailCache';
import { useDownloadURL, useScreenContext } from '../../hooks';
import { FileName } from '../FileName';
import { logEvent } from '../../util';

export const DownloadModal = (): JSX.Element | null => {
  const { shareKey, focusKey, action } = useParams();
  const navigate = useNavigate();
  const password = useContext(PasswordContext);
  const { data: assetDetails, isLoading } = useAssetDetail(shareKey || null, focusKey || null, password);
  const batch = useContext(BatchContext);
  const createDownloadLink = useDownloadURL();
  const transfered = useRef(false);
  const { isWidthSmall } = useScreenContext();

  const gotoShare = useCallback(() => {
    navigate(`/${shareKey}`);
  }, [navigate, shareKey]);
  const startDownload = useCallback(
    (downloadableAsset: PublicAssetFile) => {
      logEvent('downloadFile', 'download', {
        shareKey: shareKey || '',
        assetId: focusKey,
        childAssetId: downloadableAsset.assetId.id,
        fileName: downloadableAsset?.fileName,
        fileSize: downloadableAsset?.sizeInBytes,
      });
      window.open(
        createDownloadLink({
          shareKey: shareKey || '',
          assetId: focusKey,
          childAssetId: downloadableAsset.assetId.id,
          password: password,
        }),
      );
    },
    [createDownloadLink, shareKey, password, focusKey],
  );

  useEffect(() => {
    if (action !== 'download') {
      return;
    }

    if (isWidthSmall && assetDetails?.downloadableAssets.length === 1) {
      if (!transfered.current) {
        transfered.current = true;
        startDownload(assetDetails.downloadableAssets[0]);
        gotoShare();
      }
    }

    return () => {
      transfered.current = false;
    };
  }, [action, assetDetails, gotoShare, isWidthSmall, startDownload]);

  if (action !== 'download' || !shareKey || !focusKey) {
    return null;
  }
  if (!batch?.share) {
    throw new Error('cannot be rendered without data');
  }
  const fileData = groupByExtension(assetDetails?.downloadableAssets);
  const fileTypes = Object.keys(fileData);

  // Avoid render the modal if there is only one file to download or none only in mobile.
  if (isWidthSmall && assetDetails?.downloadableAssets && assetDetails.downloadableAssets.length <= 1) {
    return null;
  }

  return (
    <Modal disableTransition open onClose={gotoShare}>
      <ModalHeader layout={{ display: 'flex', justifyContent: 'space-between' }}>
        <AppLogo name='Asset Share' studioLogo />
        <Button variant='icon' icon={IconX} component={Link} to={`/${shareKey}`}>
          Close
        </Button>
      </ModalHeader>
      <ModalBody>
        <Heading level={2}>Downloadable files</Heading>
        {isLoading ? <Loader variant='circular' /> : null}
        {assetDetails === null && <Loader style={{ alignSelf: 'center', margin: Dimensions.Space32 }} />}
        {fileTypes.length > 0
          ? fileTypes.map((fileType) => (
              <MenuGroup key={fileType} label={`.${fileType.toUpperCase()} Files`}>
                {fileData[fileType].map((downloadableAsset) => (
                  <MenuItem
                    rootProps={{ download: true }}
                    onClick={() => startDownload(downloadableAsset)}
                    icon={IconDownload}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: Dimensions.Space12 }}>
                      <FileName level={0}>{downloadableAsset.fileName}</FileName>
                      <Text>{downloadableAsset.sizeInBytes ? bytes(downloadableAsset.sizeInBytes) : null}</Text>
                    </div>
                  </MenuItem>
                ))}
              </MenuGroup>
            ))
          : null}
      </ModalBody>
    </Modal>
  );
};

const groupByExtension = (files?: PublicAssetFile[]): Record<string, PublicAssetFile[]> =>
  (files || []).reduce((acc, item) => {
    const ext = item.fileName.split('.').pop() || 'unknown';
    if (!acc[ext]) {
      acc[ext] = [];
    }
    acc[ext].push(item);
    return acc;
  }, {} as Record<string, PublicAssetFile[]>);
