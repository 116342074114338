import { Box, Button, ButtonGroup, Input, Loader, Modal, Text } from '@hawkins/components';
import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import { Dimensions } from '@hawkins/variables';

import { ShowError } from '../ShowError';
import { Grid } from '../Grid/Grid';
import { DetailModal } from '../Detail';
import { DownloadModal } from '../DownloadModal';
import { BatchContext, PasswordContext } from '../../context';
import { useShare } from '../../hooks';
import { ShowExpired } from '../ShowExpired';
import { logError, logEvent } from '../../util';

import { Header } from './Header';

export const Main = (): JSX.Element => {
  const [password, setPassword] = useState<null | string>(null);
  const [passwordInputValue, setPasswordInputValue] = useState('');
  const { batch, isLoading, isError, accessDenied } = useShare(password);

  const checkPassword = useCallback(() => setPassword(passwordInputValue), [passwordInputValue]);
  const checkEnter = useCallback<KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (evt) => {
      if (evt.key === 'Enter') {
        checkPassword();
      }
    },
    [checkPassword],
  );
  if (isLoading) {
    return (
      <Box
        justifyContent='center'
        alignItems='center'
        display='flex'
        height='100vh'
        flexDirection='column'
        gap={Dimensions.Space16}
      >
        <Loader variant='circular' />
        <Text variant='label' bold level={2}>
          Loading
        </Text>
      </Box>
    );
  }

  if (accessDenied) {
    return (
      <Modal open>
        <Modal.Header compact title='A password is required to access this share' />
        <Modal.Body>
          <Input
            label='Password'
            description='Please input the password for this share'
            autoFocus
            type='password'
            onChange={(ev) => setPasswordInputValue(ev.target.value)}
            value={passwordInputValue}
            onKeyDown={checkEnter}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup justifyContent='flex-end'>
            <Button onClick={checkPassword}>Go to Share</Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    );
  }
  if (batch?.share.state === `EXPIRED`) {
    logEvent('batchExpired', 'batch');
    return <ShowExpired batch={batch} />;
  }
  if (!batch || isError) {
    // report?
    logError(new Error('batch loading error'));
    return <ShowError />;
  }
  return (
    <PasswordContext.Provider value={password}>
      <BatchContext.Provider value={batch}>
        <Box display='flex' flex={1} flexDirection='column' alignItems='stretch' alignContent='stretch'>
          <Header />
          <Grid />
          <DetailModal />
          <DownloadModal />
        </Box>
      </BatchContext.Provider>
    </PasswordContext.Provider>
  );
};
