import { useCallback } from 'react';

interface DownloadURLProps {
  shareKey: string;
  assetId?: string;
  childAssetId: string;
  password?: string | null;
  skipRedirect?: boolean;
}

class DownloadURLError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DownloadURL';
  }
}

export const useDownloadURL = () => {
  return useCallback(({ shareKey, assetId, childAssetId, password, skipRedirect }: DownloadURLProps): string => {
    if (!assetId) {
      throw new DownloadURLError('Asset ID is required');
    }

    const baseURL = `/share/${shareKey}/${assetId}/${childAssetId}/download/`;
    const queryParams = new URLSearchParams();
    if (password) {
      queryParams.set('password', password);
    }
    if (skipRedirect) {
      queryParams.set('skipRedirect', 'true');
    }
    const queryString = queryParams.toString();
    return `${baseURL}${queryParams ? `?${queryString}` : ''}`;
  }, []);
};
