import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAssetDetail } from '../components/batch/useDetailCache';
import { BatchContext } from '../context';

export function useCanDownload(itemId: string | null, password: string | null) {
  const { shareKey } = useParams();
  const { data: assetDetails } = useAssetDetail(shareKey || null, itemId, password);
  const batch = useContext(BatchContext);

  return useMemo(
    () =>
      batch?.share.isDownloadable === true &&
      (!assetDetails?.isVideoPlayable ||
        (assetDetails?.isVideoPlayable && assetDetails?.watermarkStatus !== 'IN_PROGRESS')),
    [assetDetails, batch?.share.isDownloadable],
  );
}
