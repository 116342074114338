import { useCanImageLoad, Tag } from '@hawkins/components';
import { AssetBrowserItem } from '@hawkins-community/asset-browser/v4';
import React, { useEffect } from 'react';
import { IconAudioClip, IconDocument, IconDocumentPdf } from '@hawkins/assets';

import { GetDetailsForAssetResponse, useAssetDetail } from '../batch/useDetailCache';
import { logEvent } from '../../util';
import { hasNoGeneratedFileForThumbnail } from '../../util/fileExtWithoutPreview';

import { ThumbnailError } from './ThumbnailError';
import { WatermarkInProgress } from './WatermarkInprogress';
import { MissingThumbnail } from './MissingThumbnail';

interface ThumbnailProps {
  children?: React.ReactNode;
  id: string;
  src: string;
  shareKey: string;
  password: string | null;
}

export const ErrorChildren = ({
  assetDetails,
}: {
  assetDetails: GetDetailsForAssetResponse | undefined;
}): JSX.Element | null => {
  if (assetDetails?.isVideoPlayable) {
    logEvent('thumbnailVideoWatermarkInProgress', 'thumbnail', {
      assetId: assetDetails?.assetId,
      watermarkStatus: assetDetails?.watermarkStatus,
      mimeType: assetDetails?.mimeType,
    });
    return assetDetails?.watermarkStatus === 'IN_PROGRESS' ? <WatermarkInProgress /> : <MissingThumbnail />;
  }

  if (assetDetails?.mimeType?.includes('audio')) {
    logEvent('thumbnailUnknownFileExt', 'thumbnail', {
      assetId: assetDetails?.assetId,
      watermarkStatus: assetDetails?.watermarkStatus,
      mimeType: assetDetails?.mimeType,
    });
    return <IconAudioClip />;
  }

  if (assetDetails?.mimeType?.includes('pdf')) {
    logEvent('thumbnailUnknownFileExt', 'thumbnail', {
      assetId: assetDetails?.assetId,
      watermarkStatus: assetDetails?.watermarkStatus,
      mimeType: assetDetails?.mimeType,
    });
    return <IconDocumentPdf />;
  }
  if (hasNoGeneratedFileForThumbnail(assetDetails?.name || '')) {
    const fileExt = assetDetails?.name?.split('.').pop();
    logEvent('thumbnailUnknownFileExt', 'thumbnail', {
      assetId: assetDetails?.assetId,
      watermarkStatus: assetDetails?.watermarkStatus,
      mimeType: assetDetails?.mimeType,
      fileExt: fileExt,
      name: assetDetails?.name,
    });
    return (
      <Tag startAdornment={<IconDocument size='small' />} color={'Black'}>
        {fileExt}
      </Tag>
    );
  }
  logEvent('thumbnailError', 'thumbnail', {
    assetId: assetDetails?.assetId,
    watermarkStatus: assetDetails?.watermarkStatus,
    mimeType: assetDetails?.mimeType,
    name: assetDetails?.name,
  });
  return (
    <Tag startAdornment={<IconDocument size='small' />} color={'Black'}>
      {assetDetails?.name}
    </Tag>
  );
};

export const Thumbnail = ({ children, id, src, shareKey, password }: ThumbnailProps): JSX.Element => {
  const status = useCanImageLoad(src);
  const { data: assetDetails } = useAssetDetail(shareKey, id, password);
  useEffect(() => {
    if (status === 'loaded') {
      logEvent('thumbnailLoad', 'thumbnail', {
        assetId: assetDetails?.assetId,
        watermarkStatus: assetDetails?.watermarkStatus,
        mimeType: assetDetails?.mimeType,
      });
    }
  }, [status, assetDetails]);
  return (
    <AssetBrowserItem
      itemKey={id}
      src={
        status === 'loaded' ? (
          src
        ) : (
          <ThumbnailError>
            <ErrorChildren assetDetails={assetDetails} />
          </ThumbnailError>
        )
      }
      alt={''}
    >
      {children}
    </AssetBrowserItem>
  );
};
