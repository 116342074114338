import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { HawkinsProvider } from '@hawkins/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HawkinsJSSProvider } from '@hawkins/styles';

import { Main, Vandam } from './components';
import { ScreenContext, useScreenContextValue } from './hooks';
import { ShowError } from './components/ShowError';
import { WarningBanner } from './components/WarningBanner';

import '@hawkins/variables/lib/css/styles.css'; // Required (this contains all the Hawkins CSS variables)
import '@hawkins/components/lib/css/styles.css'; // Required (this contains the base Hawkins component styles)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = (): JSX.Element => {
  const screenContext = useScreenContextValue();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <HawkinsProvider theme='dark'>
          <HawkinsJSSProvider theme='dark'>
            <ScreenContext.Provider value={screenContext}>
              <WarningBanner />
              <Routes>
                <Route element={<Vandam />} path='/vandam/:shareKey' />
                <Route element={<Main />} path='/:shareKey' />
                <Route element={<Main />} path='/:shareKey/:focusKey' />
                <Route element={<Main />} path='/:shareKey/:focusKey/:action' />
                <Route element={<ShowError />} path='/' />
              </Routes>
            </ScreenContext.Provider>
          </HawkinsJSSProvider>
        </HawkinsProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
