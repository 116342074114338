import React, { ReactNode, useMemo } from 'react';
import { AppLogo, ContextualBanner, ErrorState, Loader } from '@hawkins/components';
import { Unavailable } from '@hawkins-community/heroes/react/unavailable';
import { Dimensions } from '@hawkins/variables';
import { Navigate, useParams } from 'react-router-dom';

import { useVandamShare } from '../../hooks';

const statuses = ['READY', 'NOT_READY', 'INVALID', 'UNKNOWN'] as const;
type Status = typeof statuses[number];
interface ResponseJson {
  status: Status;
}
export const Vandam = (): JSX.Element => {
  const { batch, isLoading } = useVandamShare();
  const { shareKey } = useParams();
  const status = useMemo((): Status => {
    if (!isLoading && batch) {
      try {
        const str = JSON.parse(batch) as ResponseJson;
        if (str.status && statuses.includes(str.status)) {
          return str.status;
        }
      } catch (e) {
        //
      }
    }
    return 'UNKNOWN';
  }, [batch, isLoading]);
  const messageTitle = useMemo((): ReactNode => {
    if (isLoading) return 'Checking  migration status';
    if (status === 'NOT_READY') return 'Migrating share link';
    if (status === 'READY') return 'This share is migrated';
    if (status === 'INVALID') return 'This share is invalid';
    return '';
  }, [isLoading, status]);
  const messageText = useMemo((): ReactNode => {
    if (isLoading) return <Loader size={Dimensions.Size16} />;
    if (status === 'NOT_READY')
      return 'Page will automatically refresh when migration is complete. (It can take us up to 15 minutes to complete the migration of your share, please stand by)';
    if (status === 'READY') return 'Redirecting you to the share now';
    if (status === 'INVALID') return 'Please check the URL you used to get here';
    return '';
  }, [isLoading, status]);

  if (status === 'READY') {
    return <Navigate to={`/${shareKey}`} />;
  }
  return (
    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <ErrorState
        layout={{
          padding: Dimensions.Space24,
        }}
        appLogo={<AppLogo name='Asset Share' studioLogo />}
        illustration={<Unavailable />}
        subtitle={
          <ContextualBanner
            variant={status === 'INVALID' ? 'error' : 'neutral'}
            multiline
            title={messageTitle}
            text={messageText}
          />
        }
        title={`Looks like you are accessing shared assets using an old VanDAM URL.`}
      />
    </div>
  );
};
