import React from 'react';
import { Tag } from '@hawkins/components';
import { Colors } from '@hawkins/variables';

export const WatermarkInProgress = () => {
  return (
    <Tag variant={'outlined'} color={Colors.GrayT20}>
      Watermark In Progress
    </Tag>
  );
};
