import { makeStyles } from '@hawkins/styles';
import { Text, TextProps, HoverCard } from '@hawkins/components';
import React from 'react';

const useStyles = makeStyles({
  root: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
});

export const FileName = ({ children, ...rest }: TextProps) => {
  const classNames = useStyles();
  return (
    <HoverCard>
      <HoverCard.Trigger>
        <Text level={1} classes={{ root: classNames.root }} {...rest}>
          {children}
        </Text>
      </HoverCard.Trigger>
      <HoverCard.Content>
        <HoverCard.TooltipContent label={children} />
      </HoverCard.Content>
    </HoverCard>
  );
};
