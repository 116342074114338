import React, { Key, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ABContext, AssetBrowserContext, VirtualizedItem } from '@hawkins-community/asset-browser/v4';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@hawkins/styles';

import { useScreenContext } from '../../hooks';
import { BatchContext } from '../../context';
import { logError } from '../../util';

import { GridItem } from './GridItem';

const basis = { flexBasis: 'min-content' };

const useStyles = makeStyles(({ Dimensions, Utils }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: Dimensions.Space12,
    gap: Dimensions.Space12,
    flexDirection: 'column',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      flexDirection: 'row',
      padding: Dimensions.Space24,
    },
  },
}));

export const Grid = (): React.ReactElement => {
  const batch = useContext(BatchContext);
  const { isHeightSmall, isWidthSmall } = useScreenContext();
  const [highlight, setHighlight] = useState<Key | undefined>(undefined);
  const { focusKey } = useParams();

  const contextValue = useMemo<ABContext>(() => {
    return {
      fixedItemHeight: isWidthSmall || isHeightSmall ? 80 : 225,
      fixedItemWidth: isWidthSmall || isHeightSmall ? 90 : undefined,
      onItemClick: (key) => setHighlight(highlight === key ? undefined : key),
      highlight: focusKey ? undefined : highlight,
    };
  }, [focusKey, highlight, isHeightSmall, isWidthSmall]);
  const classes = useStyles();

  const initialWidth = useMemo(() => (isWidthSmall ? 90 : 300), [isWidthSmall]);
  const initialHeight = useMemo(() => (isWidthSmall ? 80 : 300), [isWidthSmall]);
  const navigate = useNavigate();
  /**
   * Redirect only once to full screen if the share has only one asset, during the initial render after
   * that avoid redirecting again, and let the user control the flow after the initial screen from the first render, this
   * redirect will only happen in mobile device and when the share has only one asset
   */
  const redirectedToFullScreen = useRef(false);

  useEffect(() => {
    if (
      isWidthSmall &&
      !redirectedToFullScreen.current &&
      batch &&
      batch?.share?.shareKey &&
      batch?.assetIds.length === 1
    ) {
      navigate(`/${batch.share.shareKey}/${batch.assetIds[0].id}`);
      redirectedToFullScreen.current = true;
    }
  }, [batch, isWidthSmall, navigate]);

  if (!batch?.share) {
    logError(new Error('cannot be rendered without data'));
    throw new Error('cannot be rendered without data');
  }

  return (
    <div className={classes.container}>
      <AssetBrowserContext.Provider value={contextValue}>
        {batch.assetIds.map((item, index) => (
          <VirtualizedItem key={item.id} initialWidth={initialWidth} initialHeight={initialHeight} style={basis}>
            <GridItem itemId={item.id} index={index} total={batch.assetIds.length} />
          </VirtualizedItem>
        ))}
      </AssetBrowserContext.Provider>
    </div>
  );
};
