import { Button } from '@hawkins/components';
import React, { useCallback } from 'react';
import { IconLinkOut } from '@hawkins/assets';

import { PublicBatch, SupportedApp } from '../batch/types';
import { logEvent } from '../../util';

interface LoginButtonProps {
  batch: PublicBatch;
  compact?: boolean;
}
export const LoginButton = ({ batch, compact }: LoginButtonProps) => {
  const logOnClick = useCallback(() => {
    logEvent('loginToApp', 'login', { app: batch?.app });
  }, [batch]);
  return (
    <Button
      style={{ alignSelf: 'flex-start' }}
      variant='secondary'
      icon={IconLinkOut}
      compact={compact}
      onClick={logOnClick}
      href={getAppUrl(batch, isTest ? 'test' : 'prod')}
    >
      {getAppLabel(batch)}
    </Button>
  );
};

interface AppConfig {
  prod: string;
  test: string;
  label: string;
}
const baseUrls: Record<SupportedApp, AppConfig> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PAL: {
    prod: 'https://pal.netflixstudios.com/share',
    test: 'https://pal.test.netflixstudios.com/share',
    label: 'Login to view in Promotional Asset Library',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VANDAM: {
    prod: 'https://vandam.netflixstudios.com/shares',
    test: 'https://vandam.test.netflixstudios.com/shares',
    label: 'Login to view in VanDAM',
  },
};
const isTest = window.location.hostname.includes('test') || window.location.hostname.includes('local');
const getPalUrl = (batch: PublicBatch): string =>
  `https://pal.${isTest ? 'test.' : ''}netflixstudios.com/share/${batch.share?.shareKey}`;
const getAppUrl = (batch: PublicBatch, env: 'prod' | 'test') => {
  const appConfig = baseUrls[batch.app];
  if (appConfig) {
    if (batch.app === 'VANDAM') {
      const shareKey = batch.share!.shareKey.replaceAll('-', '');
      return `${appConfig[env]}/${shareKey}`;
    }
    return `${appConfig[env]}/${batch.share?.shareKey}`;
  }
  return getPalUrl(batch);
};

const getAppLabel = (batch: PublicBatch): string => {
  const appConfig = baseUrls[batch.app];
  if (appConfig) {
    return appConfig.label;
  }
  return 'Login to view in Promotional Asset Library';
};
